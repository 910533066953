// Angular
import { NgModule } from '@angular/core';

// Font Awesome
import { FontAwesomeModule, FaConfig, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import * as proSolidIcons from '@fortawesome/pro-solid-svg-icons';
import * as proRegularIcons from '@fortawesome/pro-regular-svg-icons';
import * as proLightIcons from '@fortawesome/pro-light-svg-icons';

@NgModule({
  imports: [
    FontAwesomeModule,
  ],
  exports: [
    FontAwesomeModule
  ]
})

export class IconsModule {
  constructor(private faConfig: FaConfig,
              private library: FaIconLibrary) {
    // Default is Solid Icons - load entire icon pack
    this.faConfig.defaultPrefix = 'fas';

    // Load non-default icons
    this.addLightIcons();
    this.addRegularIcons();
    this.addSolidIcons();
  }

  addLightIcons() {
    this.library.addIcons(
      proLightIcons.faPhone,
      proLightIcons.faEnvelope,
      proLightIcons.faUser,
      proLightIcons.faMoneyCheckEdit,
      proLightIcons.faUsersMedical,
      proLightIcons.faUserFriends,
      proLightIcons.faCalendarCheck,
      proLightIcons.faClipboardListCheck,
    );
  }

  addRegularIcons() {
    this.library.addIcons(
      proRegularIcons.faArrowLeft,
      proRegularIcons.faBellSlash,
      proRegularIcons.faCalendarDay,
      proRegularIcons.faChartPieAlt,
      proRegularIcons.faChartPie,
      proRegularIcons.faChartMixed,
      proRegularIcons.faClock,
      proRegularIcons.faComment,
      proRegularIcons.faDownload,
      proRegularIcons.faExclamationCircle,
      proRegularIcons.faExclamationTriangle,
      proRegularIcons.faFileImage,
      proRegularIcons.faFilePdf,
      proRegularIcons.faFileDownload,
      proRegularIcons.faHandHoldingUsd,
      proRegularIcons.faHourglassStart,
      proRegularIcons.faImages,
      proRegularIcons.faInfoCircle,
      proRegularIcons.faLandmark,
      proRegularIcons.faListAlt,
      proRegularIcons.faLayerGroup,
      proRegularIcons.faLocationDot,
      proRegularIcons.faLockAlt,
      proRegularIcons.faMoneyBillAlt,
      proRegularIcons.faPercentage,
      proRegularIcons.faRadiation,
      proRegularIcons.faReceipt,
      proRegularIcons.faShieldAlt,
      proRegularIcons.faTally,
      proRegularIcons.faUserCircle,
      proRegularIcons.faUsdCircle,
      proRegularIcons.faWindowRestore,
      proRegularIcons.faCheckCircle,
      proRegularIcons.faSearch,
      proRegularIcons.faStar,
      proRegularIcons.faArrowsRotate,
      proRegularIcons.faCalendarCheck,
      proRegularIcons.faBox,
      proRegularIcons.faPlusCircle,
      proRegularIcons.faTrash,
      proRegularIcons.faFile,
      proRegularIcons.faBookmark,
      proRegularIcons.faSave,
      proRegularIcons.faFilter,
      proRegularIcons.faTimesCircle,
      proRegularIcons.faPlus,
      proRegularIcons.faSortAmountUp,
      proRegularIcons.faSortAmountDown,
      proRegularIcons.faAngleDown,
      proRegularIcons.faUserShield,
      proRegularIcons.faCogs,
      proRegularIcons.faUserUnlock,
      proRegularIcons.faUsersMedical,
      proRegularIcons.faContainerStorage,
      proRegularIcons.faFileInvoiceDollar
    );
  }

  addSolidIcons() {
    this.library.addIcons(
      proSolidIcons.faAnalytics,
      proSolidIcons.faAngleDown, // do we use this one?
      proSolidIcons.faAngleLeft,
      proSolidIcons.faAngleRight,
      proSolidIcons.faArrowCircleUp,
      proSolidIcons.faArrowLeft,
      proSolidIcons.faArrowRight,
      proSolidIcons.faArrowUp,
      proSolidIcons.faAward,
      proSolidIcons.faBan,
      proSolidIcons.faCalendarAlt,
      proSolidIcons.faCalendarCheck,
      proSolidIcons.faCalendarPlus,
      proSolidIcons.faCalendarTimes,
      proSolidIcons.faCaretDown,
      proSolidIcons.faCaretUp,
      proSolidIcons.faChartBar,
      proSolidIcons.faChartLine,
      proSolidIcons.faChartNetwork,
      proSolidIcons.faCheck,
      proSolidIcons.faCheckCircle,
      proSolidIcons.faCheckSquare,
      proSolidIcons.faChevronCircleLeft,
      proSolidIcons.faChevronCircleRight,
      proSolidIcons.faChevronLeft,
      proSolidIcons.faChevronRight,
      proSolidIcons.faChevronDown,
      proSolidIcons.faChevronUp,
      proSolidIcons.faCircle,
      proSolidIcons.faClipboardList,
      proSolidIcons.faClipboardUser,
      proSolidIcons.faClock,
      proSolidIcons.faCog,
      proSolidIcons.faCogs,
      proSolidIcons.faComment,
      proSolidIcons.faComments,
      proSolidIcons.faCommentDollar,
      proSolidIcons.faCompactDisc,
      proSolidIcons.faCompressArrowsAlt,
      proSolidIcons.faContainerStorage,
      proSolidIcons.faEdit,
      proSolidIcons.faEllipsisV,
      proSolidIcons.faEnvelope,
      proSolidIcons.faExclamation,
      proSolidIcons.faExclamationCircle,
      proSolidIcons.faExclamationTriangle,
      proSolidIcons.faFile,
      proSolidIcons.faFileArrowUp,
      proSolidIcons.faFileAlt,
      proSolidIcons.faFileCheck,
      proSolidIcons.faFileCsv,
      proSolidIcons.faFileDownload,
      proSolidIcons.faFileExcel,
      proSolidIcons.faFileExport,
      proSolidIcons.faFilePdf,
      proSolidIcons.faFileWord,
      proSolidIcons.faGavel,
      proSolidIcons.faGraduationCap,
      proSolidIcons.faHammer,
      proSolidIcons.faHandshake,
      proSolidIcons.faHistory,
      proSolidIcons.faHourglassEnd,
      proSolidIcons.faHourglassStart,
      proSolidIcons.faInfo,
      proSolidIcons.faInfoCircle,
      proSolidIcons.faLandmark,
      proSolidIcons.faLock,
      proSolidIcons.faLightbulb,
      proSolidIcons.faMailBulk,
      proSolidIcons.faMinus,
      proSolidIcons.faMoneyCheckAlt,
      proSolidIcons.faPaperclip,
      proSolidIcons.faPencil,
      proSolidIcons.faPercentage,
      proSolidIcons.faPlus,
      proSolidIcons.faPrintSearch,
      proSolidIcons.faRecycle,
      proSolidIcons.faRedo,
      proSolidIcons.faRocket,
      proSolidIcons.faSave,
      proSolidIcons.faSearch,
      proSolidIcons.faSignature,
      proSolidIcons.faSort,
      proSolidIcons.faSortDown,
      proSolidIcons.faStar,
      proSolidIcons.faArrowsRotate,
      proSolidIcons.faStore,
      proSolidIcons.faSync,
      proSolidIcons.faSyncAlt,
      proSolidIcons.faTachometerAlt,
      proSolidIcons.faTimes,
      proSolidIcons.faTrashAlt,
      proSolidIcons.faUndo,
      proSolidIcons.faUpload,
      proSolidIcons.faUserCircle,
      proSolidIcons.faUserUnlock,
      proSolidIcons.faUserShield,
      proSolidIcons.faUsersMedical,
      proSolidIcons.faWarning,

      // commenting these out because no use was found in the app.
      // If console errors pop up in testing then we use them, otherwise leave commented then delete on~september 2022
      // proSolidIcons.faAngleDoubleLeft, // do we use?
      // proSolidIcons.faArrowAltCircleUp, // do we use?
      // proSolidIcons.faAsterisk, // do we use?
      // proSolidIcons.faBars, // do we use?
      // proSolidIcons.faCalendarExclamation, // do we use?
      // proSolidIcons.faCalendarMinus, // do we use?
      // proSolidIcons.faCaretCircleLeft, // do we use?
      // proSolidIcons.faCaretCircleRight, // do we use?
      // proSolidIcons.faChartArea, // do we use?
      // proSolidIcons.faChartScatter, // do we use?
      // proSolidIcons.faDotCircle, // do we use?
      // proSolidIcons.faEnvelopeOpen, // do we use?
      // proSolidIcons.faGlobeAmericas, // do we use
    );
  }
}
